import { AppConfigGen } from './AppConfigGen'

export const AppConfig = {
  ...AppConfigGen,
  DRAWER_STYLE: 'TYPE1',
  //LOGO: require('./assets/images/logo_transparent.svg'),
  LOGO: require('./assets/images/icon-1024-transparent.png'),
  ALLOWMOCK: false,
  // ENDPOINT: 'http://127.0.0.1:3000',
  VERSION: '1.0.55',
  CUSTOM_STYLE: true,
  USER_ROLES_PRODUCT: [
    { key: 'SUPERUSER', label: 'ADMIN' }, // Super Admin
    { key: 'REGULAR', label: 'REGULAR' }
  ],
  USER_ROLES_ORG: [
    { key: 'SUPERUSER', label: 'ADMIN' }, // Org Admin
    { key: 'CREATOR', label: 'CREATOR' },
    { key: 'MANAGER', label: 'MANAGER' },
  ],
  USER_ROLES_SUBORG: [
    { key: 'MANAGER', label: 'MANAGER' },
  ],
  TERMS: '/terms-of-use',
  PRIVACY: '/privacy-policy',
  TITLE: 'SincX',
  DESCR: 'Employee training - Simplified',
  CONTACT_EMAIL: 'contact@sincxapp.com',
}
